import styled from 'styled-components';

export const BackgroundShadow1 = styled.div`
  position: absolute !important;
  top: 30rem;
  right: 5rem;
  width: 125rem;
  z-index: 0;
  height: 89rem;
  background: linear-gradient(
    230.26deg,
    rgb(47 57 101 / 0%) 14.93%,
    rgba(38, 46, 79, 0.5) 25.01%,
    rgb(91 109 220 / 5%) 42.49%
  );
  -webkit-transform: rotate(-33deg);
  -ms-transform: rotate(-33deg);
  transform: rotate(190deg);
`;

export const BackgroundShadow2 = styled.div`
  position: absolute !important;
  top: 31rem;
  right: 6rem;
  width: 130rem;
  z-index: 0;
  height: 89rem;
  background: linear-gradient(
    230.26deg,
    rgb(47 57 101 / 0%) 14.93%,
    rgba(38, 46, 79, 0.5) 25.01%,
    rgb(91 109 220 / 5%) 42.49%
  );
  -webkit-transform: rotate(-33deg);
  -ms-transform: rotate(-33deg);
  transform: rotate(190deg);
`;

const FeatureSliderWrapper = styled.div`
  /*background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #2a313c;*/

  position: relative;
  padding-top: 180px;
  margin-bottom: 200px;
  @media (max-width: 1440px) {
    padding-top: 140px;
  }
  .FeatureSliderInnerX {
    span:nth-child(1) {
      position: absolute;
      display: block;
      width: 5%;
      padding-bottom: 5%;
      border-radius: 50%;
      top: 60%;
      left: 50%;
      opacity: 0;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      -webkit-animation: pulsei 4.2s ease-out infinite;
      animation: pulsei 4.2s ease-out infinite;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      pointer-events: none;
    }
    span:nth-child(2) {
      content: '';
      position: absolute;
      display: block;
      width: 5%;
      padding-bottom: 5%;
      border-radius: 50%;
      top: 60%;
      left: 50%;
      opacity: 0;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      -webkit-animation: pulsei 4.2s ease-out infinite;
      animation: pulsei 4.2s ease-out infinite;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      pointer-events: none;
      -webkit-animation-delay: 1s;
      animation-delay: 1s;
    }
    span:nth-child(3) {
      content: '';
      position: absolute;
      display: block;
      width: 5%;
      padding-bottom: 5%;
      border-radius: 50%;
      top: 60%;
      left: 50%;
      opacity: 0;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      -webkit-animation: pulsei 4.2s ease-out infinite;
      animation: pulsei 4.2s ease-out infinite;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      pointer-events: none;
      -webkit-animation-delay: 2s;
      animation-delay: 2s;
    }
  }
  .FeatureSliderX {
    padding-top: 200px;
    padding-bottom: 200px;
    position: relative;

    .image-gallery {
      position: relative;
      z-index: 2;
    }

    .image-gallery-slides {
      border-radius: 45%;
    }
    .image-gallery-slide {
      background: none;
    }

    @-webkit-keyframes pulsei {
      0% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
          scale(1);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        //border: 2px solid rgba(82, 189, 149, 0.7);
        background: rgba(82, 189, 149, 0.5);
        opacity: 1;
        width: 5%;
        padding-bottom: 5%;
      }

      100% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
          scale(1);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 0;
        width: 67%;
        //border: 2px solid rgba(82, 189, 149, 0.7);
        background: rgba(82, 189, 149, 0.5);
        padding-bottom: 67%;
      }
    }
    @keyframes pulsei {
      0% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
          scale(1);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        //border: 2px solid rgba(82, 189, 149, 0.7);
        background: rgba(82, 189, 149, 0.5);
        opacity: 1;
        width: 5%;
        padding-bottom: 5%;
      }

      100% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
          scale(1);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 0;
        width: 67%;
        //border: 2px solid rgba(82, 189, 149, 0.7);
        background: rgba(82, 189, 149, 0.5);
        padding-bottom: 67%;
      }
    }

    .image-gallery-slide-wrapper {
      width: 150px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      height: auto;
    }
    .image-gallery-thumbnails {
      padding-bottom: 0;
    }
    .image-gallery-thumbnails-wrapper {
      position: static;

      .image-gallery-thumbnails-container {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        top: 0;
        left: 0;

        .image-gallery-thumbnail {
          border: 0;
          width: 125px;
          .image-gallery-thumbnail-inner {
            outline: none;
            &:focus {
              outline: none;
            }
          }
          img {
            -webkit-transition: all 0.35s ease;
            transition: all 0.35s ease;
            width: 100px;
          }

          &:nth-child(1) {
            position: absolute;
            top: -200px;
            left: 27.666%;
          }
          &:nth-child(2) {
            position: absolute;
            top: -200px;
            right: 27.666%;
          }
          &:nth-child(3) {
            position: absolute;
            top: 50%;
            right: 0;
            margin-right: 120px;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
          }
          &:nth-child(4) {
            position: absolute;
            bottom: -200px;
            right: 27.666%;
          }
          &:nth-child(5) {
            position: absolute;
            bottom: -200px;
            left: 27.666%;
          }
          &:nth-child(6) {
            position: absolute;
            top: 50%;
            left: 0;
            margin-left: 120px;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
          }
          .image-gallery-thumbnail-label {
            position: relative;
            margin-top: 10px;
            font-size: 19px;
            line-height: 24px;
            letter-spacing: -0.01em;
            color: #2a313c;
            font-family: 'Open sans';
            top: 0;
            text-shadow: none;
            -webkit-transform: none;
            transform: none;
            white-space: normal;
            width: 100%;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
              'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
              'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
          }
          &.active {
            border: 0;
            .image-gallery-thumbnail-label {
              margin-top: 30px;
            }
            img {
              -webkit-transition: all 0.35s ease;
              transition: all 0.35s ease;
              -webkit-transform: scale(1.4);
              transform: scale(1.4);
              border: 0;
            }
          }
        }
      }
    }
    .image-gallery-bullets {
      bottom: auto;
      margin: 0;
      position: absolute;
      width: 100%;
      z-index: 4;
      top: 43%;
      right: -65px;
      left: auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      .image-gallery-bullets-container {
        margin: 0;
        padding: 0;
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 32px;
        .image-gallery-bullet {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border-radius: 70px;
          cursor: pointer;
          display: inline-block;
          outline: none;
          width: 19px;
          height: 4px;
          background: rgb(220, 226, 231);
          border: 0;
          box-shadow: none;
          padding: 0;
          margin: 0;
          margin-bottom: 10px;
          -webkit-transition: all 0.3s ease;
          transition: all 0.3s ease;
          &.active {
            background-color: rgb(26, 115, 232);
            width: 32px;
            height: 4px;
          }
        }
      }
    }
  }
`;
export default FeatureSliderWrapper;
