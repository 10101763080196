import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import VisitorSectionWrapper, {
  SectionObject,
  ButtonWrapper,
} from './targetSection.style';
import ImageOne from 'common/src/assets/image/saas/prospect_view.png';
import ImageBg from 'common/src/assets/image/saas/visitor_bg.png';
import TextHr from 'common/src/components/textLine';
import {
  TopBootstrap,
  BottomBootstrap,
} from 'common/src/components/bootstrapView/index.style';

const targets = [
  {
    id: 1,
    otherIcon: 'searchPersonaIcon',
    title: 'Buyer Persona',
    description:
      'Buyer Personas helps your team to focus on key persona within a company. Getting your message to the right person is key in account-based marketing.',
  },
  {
    id: 2,
    otherIcon: 'icpIcon',
    title: 'Ideal Customer Profile',
    description:
      'Ideal Customer Profiles helps your team to focus and identify key accounts for each specific campaign and product. Stop wasting time by going after the wrong profiles.',
  },
];

const TargetSection = ({
  title,
  description,
  textArea,
  imageWrapper,
  btnStyle,
  sectionSubTitle,
  row,
  featureCol,
  featureDescription,
  iconStyle,
  headingStyle,
  imageDashboard,
  handleOpenDemoModal,
}) => {
  const ImageView = () => (
    <SectionObject id="targets">
      <Card className="objectWrapper" {...imageWrapper}>
        <Zoom>
          <Image
            src={ImageBg}
            alt="BgImage"
            style={{ transform: 'rotateY(180deg)' }}
          />
        </Zoom>
        <Card className="dashboardWrapper" {...imageWrapper}>
          <Fade right>
            <Image src={ImageOne} alt="VisitorDashboard1" {...imageDashboard} />
          </Fade>
        </Card>
      </Card>
    </SectionObject>
  );
  return (
    <VisitorSectionWrapper id="targeting">
      <TopBootstrap>
        <ImageView />
      </TopBootstrap>
      <Container>
        <Box {...textArea}>
          <FeatureBlock
            title={
              <>
                <TextHr />
                <Text content="TARGETS" {...sectionSubTitle} />
                <Heading
                  content="Make sure you engage with the right prospects."
                  {...title}
                />
              </>
            }
            description={
              <Box className="row" {...row} {...textArea} ml={[0, 0, 0]}>
                {targets.map((feature, index) => (
                  <Box
                    className="col"
                    {...featureCol}
                    key={`quality_feature-${index}`}
                  >
                    <FeatureBlock
                      otherIcon={feature.otherIcon}
                      imgHeight="55px"
                      icon={<i className={feature.icon} />}
                      iconPosition="left"
                      iconStyle={iconStyle}
                      title={
                        <Heading content={feature.title} {...headingStyle} />
                      }
                      description={
                        <Text
                          content={feature.description}
                          {...featureDescription}
                        />
                      }
                    />
                  </Box>
                ))}
              </Box>
            }
            button={
              <ButtonWrapper>
                <Button
                  className="contained"
                  variant="contained"
                  title="GET IN TOUCH"
                  {...btnStyle}
                  onClick={handleOpenDemoModal}
                />
              </ButtonWrapper>
            }
          />
        </Box>
      </Container>
      <BottomBootstrap>
        <ImageView />
      </BottomBootstrap>
    </VisitorSectionWrapper>
  );
};

TargetSection.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
  row: PropTypes.object,
  textArea: PropTypes.object,
  featureCol: PropTypes.object,
  featureDescription: PropTypes.object,
  iconStyle: PropTypes.object,
  headingStyle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  imageDashboard: PropTypes.object,
};

TargetSection.defaultProps = {
  imageDashboard: {
    style: {
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
      boxShadow: 'rgba(0, 0, 0, 0.6) 11px 5px 6px 4px',
    },
  },
  iconStyle: {
    width: '54px',
    height: '54px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
    color: '#ffffff',
    overflow: 'hidden',
    mt: '6px',
    mr: '22px',
    flexShrink: 0,
  },
  headingStyle: {
    fontSize: '20px',
    fontWeight: '400',
    color: '#2a313c',
    letterSpacing: '-0.010em',
    mb: '5px',
    lineHeight: '1.5',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  featureDescription: {
    fontSize: '15px',
    lineHeight: '1.84',
    color: '#343d48cc',
  },
  featureCol: {
    width: [1, 1, 1],
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '100%', '70%'],
    ml: [0, 0],
  },
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#5268db',
    mb: '10px',
  },
  imageWrapper: {
    boxShadow: 'none',
  },
  title: {
    fontSize: ['20px', '20px', '24px', '24px', '28px'],
    fontWeight: '400',
    color: '#2a313c',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '80%', '80%'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
    maxWidth: ['100%', '100%', '100%', '60%', '60%'],
  },
  btnStyle: {
    marginTop: '20px',
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
};

export default TargetSection;
