import styled from 'styled-components';

const VisitorSectionWrapper = styled.section`
  min-height: 630px;
  display: flex;
  align-items: center;
  background: white;
  padding: 100px 0;
  position: relative;
  @media only screen and (max-width: 1200px) {
    min-height: 500px;
    padding-bottom: 55px;
  }
  @media only screen and (max-width: 991px) {
    min-height: 370px;
    padding-bottom: 50px;
  }
  @media (max-width: 767px) {
    min-height: auto;
    display: block;
  }
`;

const SectionObject = styled.div`
  background: white;
  position: absolute;
  width: 55%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 767px) {
    width: 100%;
    position: relative;
    height: auto;
    top: auto;
    left: auto;
  }
  img {
    max-width: 93%;
    height: auto;
  }
  .objectWrapper {
    margin-right: auto;
    position: relative;
    .dashboardWrapper {
      position: absolute;
      top: 4vw;
      left: 0;
    }
  }
`;

const CheckColumnOne = styled.div`
  display: inline-block;
  width: 40%;
  @media (max-width: 1000px) {
    width: 50%;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export { SectionObject, CheckColumnOne };

export default VisitorSectionWrapper;
