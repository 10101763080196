import React, { Fragment, useState, useEffect } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/src/theme/saas';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
  ConditionWrapper,
} from '../containers/Saas/saas.style';
import Navbar from '../containers/Saas/Navbar';
import Footer from '../containers/Saas/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import FeatureSlider from '../containers/Saas/FeatureSlider';
import FeatureSliderTwo from '../containers/Saas/FeatureSliderTwo';
import SEO from '../components/seo';
import CallToActionBottom from '../containers/Saas/TrialSection';
import Features1Section from '../containers/saas/Features1Section';
import Features2Section from '../containers/Saas/Features2Section/indexNew';
import Features3Section from '../containers/Saas/Features3Section';
import TeamSection from '../containers/Saas/TeamSection/indexNew';
import TargetSection from '../containers/Saas/targetSection';
import AnalyticsSection from '../containers/Saas/AnalyticsSection';
import Layout from '../components/layout';
import SafariFeatures from '../containers/Saas/featuresSafari';

function getSize() {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  };
}

function useWindowSize() {
  let [windowSize, setWindowSize] = useState(getSize());

  function handleResize() {
    setWindowSize(getSize());
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}

function useGetBrowser() {
  let [isSafari, setWindowSize] = useState(false);

  useEffect(() => {
    var isSafarii =
      /constructor/i.test(window.HTMLElement) ||
      (function(p) {
        return p.toString() === '[object SafariRemoteNotification]';
      })(
        !window['safari'] ||
          (typeof window.safari !== 'undefined' &&
            window.safari.pushNotification)
      );
    setWindowSize(isSafarii);
  }, []);

  return isSafari;
}

export default () => {
  const size = process.browser && useWindowSize();
  const innerWidth = process.browser && size.innerWidth;
  const safari = process.browser && useGetBrowser();

  useEffect(() => {
    if (window) {
      var hash = window.location.hash;
      if (hash) {
        setTimeout(() => {
          hash = hash.slice(1, hash.length);
          var element = document.getElementById(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 1500);
      }
    }
  }, []);

  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO
          title="Explore features"
          description="Explore our features and learn how SalesOcean can bring your lead generation process to the next-level."
        />
        <ResetCSS />
        <GlobalStyle />
        <Layout>
          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            <ConditionWrapper id="keyfeature">
              {innerWidth > 1100 ? (
                safari ? (
                  <SafariFeatures />
                ) : (
                  <FeatureSlider />
                )
              ) : (
                <FeatureSliderTwo />
              )}
            </ConditionWrapper>
            <Features1Section />
            <Features2Section />
            <Features3Section />
            <TeamSection />
            <TargetSection />
            <AnalyticsSection />
            <CallToActionBottom />
            <Footer />
          </ContentWrapper>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
