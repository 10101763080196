import React from 'react';
import { ConnectImgOne, ConnectImgTwo } from './connect.style';
import connectImg from 'common/src/assets/image/saas/connect1_new.png';

const ConnectImgs = ({ top }) => {
  return (
    <>
      <ConnectImgOne top={top}>
        <img src={connectImg} alt="connect image" />
      </ConnectImgOne>
      <ConnectImgTwo top={top}>
        <img src={connectImg} alt="connect image" />
      </ConnectImgTwo>
    </>
  );
};

export default ConnectImgs;
