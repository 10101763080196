import styled from 'styled-components';

const VisitorSectionWrapper = styled.section`
  min-height: 630px;
  display: flex;
  align-items: center;
  margin: 100px 0;
  position: relative;
  @media only screen and (max-width: 1200px) {
    min-height: 500px;
    margin-bottom: 45px;
  }
  @media only screen and (max-width: 991px) {
    min-height: 370px;
    margin-bottom: 40px;
  }
  @media (max-width: 767px) {
    min-height: auto;
    display: block;
    padding-bottom: 50px;
  }
`;

export const ButtonWrapper = styled.div`
  @media (max-width: 767px) {
    text-align: center;
  }
`;

const SectionObject = styled.div`
  position: absolute;
  width: 45%;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 767px) {
    width: 100%;
    position: relative;
    height: auto;
    top: auto;
    right: auto;
    padding-bottom: 30px;
    img {
      max-width: 90% !important;
      height: auto;
      margin: auto !important;
      float: inherit !important;
    }
  }
  @media (min-width: 767px) {
    &:hover {
      -webkit-transition: width 1s ease-in-out;
      -moz-transition: width 1s ease-in-out;
      -o-transition: width 1s ease-in-out;
      transition: width 1s ease-in-out;
      right: 0;
      width: 55%;
      .objectWrapper {
        margin-right: 0;
      }
    }
    :not(:hover) {
      -webkit-transition: width 1s ease-in-out;
      -moz-transition: width 1s ease-in-out;
      -o-transition: width 1s ease-in-out;
      transition: width 1s ease-in-out;
      width: 45%;
      .objectWrapper {
        margin-right: 0;
      }
    }
  }
  img {
    max-width: 100%;
    height: auto;
    float: right;
  }
  .objectWrapper {
    margin-right: auto;
    position: relative;
    .dashboardWrapper {
      position: absolute;
      top: 4vw;
      right: 0;
    }
  }
`;

export { SectionObject };

export default VisitorSectionWrapper;
