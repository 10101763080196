import styled from 'styled-components';

const QualitySectionWrapper = styled.section`
  padding: 110px 0 100px;
  min-height: fit-content;
  overflow: hidden;

  @media (max-width: 990px) {
    padding: 60px 0 30px 0;
  }

  @media only screen and (min-width: 1366px) {
    min-height: fit-content;
  }

  .info-sec-container {
    width: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
  .objectWrapper {
    margin-right: auto;
    position: relative;
    .dashboardWrapper {
      position: absolute;
      top: 4vw;
      right: 0;
    }
  }

  .feature__block {
    margin: 15px 0;
    .icon__wrapper {
      flex-shrink: 0;
      position: relative;

      .flaticon-flask {
        &:before {
          margin-left: 6px;
        }
      }
      &:before,
      &:after {
        content: '';
        width: 24px;
        height: 100%;
        position: absolute;
      }
    }
  }

  .row {
    > .col {
      &:nth-child(-n + 3) {
      }

      &:nth-child(3n + 3),
      &:last-child {
      }
      &:nth-child(1) {
        .feature__block {
          .icon__wrapper {
            background: #fff5f6;
            color: #f55767;
          }
        }
      }

      &:nth-child(2) {
        .feature__block {
          .icon__wrapper {
            background: #ebfff2;
            color: #29c05e;
          }
        }
      }
      &:nth-child(3) {
        .feature__block {
          .icon__wrapper {
            background: #f1faff;
            color: #2595d4;
          }
        }
      }
      &:nth-child(4) {
        .feature__block {
          .icon__wrapper {
            background: #fffae8;
            color: #e9b600;
          }
        }
      }
      &:nth-child(5) {
        .feature__block {
          .icon__wrapper {
            background: #f5eeff;
            color: #a55cef;
          }
        }
      }
      &:nth-child(6) {
        .feature__block {
          .icon__wrapper {
            background: #ffecfa;
            color: #e764a5;
          }
        }
      }
    }
  }
`;

const SectionObject = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 1400px) {
    width: 34%;
  }
  @media (max-width: 1000px) {
    width: 100%;
    position: relative;
    height: auto;
    top: auto;
    left: auto;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .objectWrapper {
    margin-right: auto;
    position: relative;
    width: 95%;
    .dashboardWrapper {
      position: absolute;
      top: 4vw;
      left: 0;
    }
  }
`;

const ZoomDiv = styled.div`
  width: 37%;
  &:hover {
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
    width: 50%;
  }
  :not(:hover) {
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
    width: 37%;
  }
`;

export { SectionObject, ZoomDiv };

export default QualitySectionWrapper;
