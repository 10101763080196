import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import QualitySectionWrapper, {
  SectionObject,
  ZoomDiv,
} from './features3.style';
import Zoom from 'react-reveal/Zoom';
import ImageBg from 'common/src/assets/image/saas/visitor_bg.png';
import TextHr from 'common/src/components/textLine';
import AutomationImgSlider from 'common/src/components/automationImgSlider';

const Features3Section = ({
  row,
  col,
  title,
  featureCol,
  description,
  textArea,
  imageArea,
  imageAreaRow,
  imageWrapper,
  featureTitle,
  featureDescription,
  iconStyle,
  sectionSubTitle,
  sequenceImage,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasJson {
        Automation {
          id
          title
          description
          icon
          otherIcon
        }
      }
    }
  `);

  return (
    <QualitySectionWrapper id="automation">
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col} {...textArea}>
            <FeatureBlock
              title={
                <>
                  <TextHr />
                  <Text content="AUTOMATION AT CORE" {...sectionSubTitle} />
                  <Heading content="The power of sequences." {...title} />
                </>
              }
            />
          </Box>
        </Box>
        <Box className="row" {...row} {...textArea}>
          {Data.saasJson.Automation.map((feature, index) => (
            <Box
              className="col"
              {...featureCol}
              key={`quality_feature-${index}`}
            >
              <FeatureBlock
                otherIcon={feature.otherIcon}
                icon={<i className={feature.icon} />}
                iconPosition="left"
                iconStyle={iconStyle}
                title={<Heading content={feature.title} {...featureTitle} />}
                description={
                  <Text content={feature.description} {...featureDescription} />
                }
              />
            </Box>
          ))}
        </Box>

        <Box className="row" {...row} {...imageAreaRow}>
          <Box className="col" {...col} {...imageArea}>
            <SectionObject>
              <ZoomDiv>
                <Card className="objectWrapper" {...imageWrapper}>
                  <Zoom>
                    <Image src={ImageBg} alt="BgImage" />
                  </Zoom>
                  <Card className="dashboardWrapper" {...imageWrapper}>
                    <Fade right delay={90}>
                      <AutomationImgSlider />
                    </Fade>
                  </Card>
                </Card>
              </ZoomDiv>
            </SectionObject>
          </Box>
        </Box>
      </Container>
    </QualitySectionWrapper>
  );
};

Features3Section.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  featureCol: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  sequenceImage: PropTypes.object,
};

Features3Section.defaultProps = {
  sequenceImage: {
    borderBottomLeftRadius: '5px',
    borderTopLeftRadius: '5px',
    boxShadow: '0px 0px 7px 0px',
  },
  // Quality section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'left',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#5268db',
    mb: '10px',
  },

  // Quality section iamge row default style
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  // Quality section col default style
  col: {
    pr: '15px',
    pl: '15px',
  },
  // Quality feature col default style
  featureCol: {
    width: [1, 1, 1 / 2],
    pr: '15px',
    pl: '15px',
  },
  // Quality section text area default style
  textArea: {
    width: [1, '100%', '100%', '70%', '68%'],
  },
  // Quality section image area default style
  imageArea: {
    width: [1, '100%', '100%', '30%', '38%'],
    flexBox: true,
    flexDirection: 'row-reverse',
  },
  // Quality section image wrapper default style
  imageWrapper: {
    boxShadow: 'none',
    //width: '100%'
  },
  // Quality section title default style
  title: {
    fontSize: ['26px', '26px', '32px', '40px'],
    lineHeight: '1.5',
    fontWeight: '300',
    color: '#2a313c',
    letterSpacing: '-0.025em',
    mb: '20px',
  },
  // Quality section description default style
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '2.1',
    mb: '33px',
  },
  // feature icon default style
  iconStyle: {
    width: '54px',
    height: '54px',
    borderRadius: '50%',
    bg: '#93d26e',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
    color: '#ffffff',
    overflow: 'hidden',
    mt: '6px',
    mr: '22px',
    flexShrink: 0,
  },
  // feature title default style
  featureTitle: {
    fontSize: ['18px', '20px'],
    fontWeight: '400',
    color: '#2a313c',
    lineHeight: '1.5',
    mb: '8px',
    letterSpacing: '-0.020em',
  },
  // feature description default style
  featureDescription: {
    fontSize: '15px',
    lineHeight: '1.84',
    color: '#343d48cc',
  },
};

export default Features3Section;
