import React from 'react';
import Image from 'common/src/components/Image';
import ImageOne from 'common/src/assets/image/saas/sequence_screenshot.png';
import TempalateImg from 'common/src/assets/image/saas/templateView.png';
import Tabs, { TabPane } from 'rc-tabs';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import 'rc-tabs/assets/index.css';
import TabContent from 'rc-tabs/lib/TabContent';
import Icon from 'react-icons-kit';
import TaskList from 'common/src/assets/image/saas/finalTasks.png';
import { ic_assignment } from 'react-icons-kit/md/ic_assignment';
import { ic_email } from 'react-icons-kit/md/ic_email';
import { ic_send } from 'react-icons-kit/md/ic_send';
import AutomationSliderStyle from './automation.style';

const SCREENSHOTS = [
  {
    icon: ic_send,
    title: 'Sequence',
    image: ImageOne,
  },
  {
    icon: ic_email,
    title: 'Template',
    image: TempalateImg,
  },
];

const AutomationImgSlider = () => {
  return (
    <AutomationSliderStyle>
      <Tabs
        renderTabBar={() => <ScrollableInkTabBar />}
        renderTabContent={() => <TabContent animated={false} />}
        className="update-screen-tab"
      >
        {SCREENSHOTS.map((item, index) => (
          <TabPane
            tab={
              <>
                <Icon icon={item.icon} size={24} /> {item.title}
              </>
            }
            key={index + 1}
          >
            <Image src={item.image} alt={`screenshot-${index + 1}`} />
          </TabPane>
        ))}
      </Tabs>
    </AutomationSliderStyle>
  );
};

export default AutomationImgSlider;
