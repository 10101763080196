import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import VisitorSectionWrapper, {
  SectionObject,
  CheckColumnOne,
} from './features1.style';
import TextHr from 'common/src/components/textLine';
import CheckedText from 'common/src/components/checkedText';

import ImageOne from 'common/src/assets/image/saas/5123.jpg';
import ImageBg from 'common/src/assets/image/saas/visitor_bg.png';

const VisitorSection = ({
  title,
  description,
  textArea,
  imageWrapper,
  btnStyle,
  sectionSubTitle,
}) => {
  return (
    <VisitorSectionWrapper id="beyondABM">
      <SectionObject>
        <Card className="objectWrapper" {...imageWrapper}>
          <Fade left>
            <Image
              src={ImageOne}
              alt="VisitorDashboard1"
              style={{
                maxWidth: '80%',
                borderBottomRightRadius: '5px',
                borderTopRightRadius: '5px',
                height: 'auto',
              }}
            />
          </Fade>
          <Card className="dashboardWrapper" {...imageWrapper}></Card>
        </Card>
      </SectionObject>
      <Container>
        <Box {...textArea}>
          <FeatureBlock
            title={
              <>
                <TextHr />
                <Text content="STRUCTURED ABM" {...sectionSubTitle} />
                <Heading
                  content="Organize all Marketing and Sales activities in one platform."
                  {...title}
                />
              </>
            }
            description={
              <>
                <Text
                  content="All Sales and Marketing activities are organized around the products or services you’re offering. This lets you bring in all the tools needed for an optimal ABM strategy, like:"
                  {...description}
                />
                <CheckColumnOne>
                  <CheckedText text="Buyer personas" />
                  <CheckedText text="Ideal Customer Profiles" />
                  <CheckedText text="Value Propositions" />
                </CheckColumnOne>
                <CheckColumnOne>
                  <CheckedText text="Templates" />
                  <CheckedText text="Sequences" />
                  <CheckedText text="Reporting" />
                </CheckColumnOne>
                <Text
                  content={
                    <span>
                      These tools help you organize your strategies by
                      segmenting the accounts in{' '}
                      <span style={{ color: '#5268db' }}> Campaigns </span> and
                      make sure your Reps engage with the right targets.
                    </span>
                  }
                  {...description}
                />
              </>
            }
            button={
              <Button
                title="GET A DEMO"
                {...btnStyle}
                onClick={() => window.open('/demo', '_self')}
              />
            }
          />
        </Box>
      </Container>
    </VisitorSectionWrapper>
  );
};

VisitorSection.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

VisitorSection.defaultProps = {
  textArea: {
    width: ['100%', '100%', '70%'],
    ml: [0, 0, '58%'],
  },
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#5268db',
    mb: '10px',
  },
  imageWrapper: {
    boxShadow: 'none',
    style: { paddingTop: '2rem' },
  },
  title: {
    fontSize: ['20px', '20px', '24px', '24px', '28px'],
    fontWeight: '400',
    color: '#2a313c',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '80%', '70%', '70%'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    maxWidth: ['100%', '100%', '70%', '60%', '60%'],
    marginBottom: '0.5rem',
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    mt: '10px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
};

export default VisitorSection;
