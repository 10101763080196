import styled from 'styled-components';

export const ConnectImgOne = styled.div.attrs(props => ({
  top: props.top || '-66px',
}))`
  img {
    position: absolute;
    right: 2rem;
    top: ${props => props.top};
    transform: rotate(180deg);
    width: 150px;
    @media (max-width: 800px) {
      right: 1rem;
      top: -44px;
      width: 100px;
    }
    @media (max-width: 500px) {
      right: 0.5rem;
      top: -33px;
      width: 75px;
    }
  }
`;

export const ConnectImgTwo = styled.div.attrs(props => ({
  top: props.top || '-66px',
}))`
  img {
    position: absolute;
    left: 2rem;
    bottom: ${props => props.top};
    width: 150px;
    @media (max-width: 800px) {
      left: 1rem;
      bottom: -44px;
      width: 100px;
    }
    @media (max-width: 500px) {
      left: 0.5rem;
      bottom: -33px;
      width: 75px;
    }
  }
`;
