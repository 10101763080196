import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import 'react-image-gallery/styles/css/image-gallery.css';
import Container from 'common/src/components/UI/Container';
import FeatureSliderWrapper, {
  BackgroundShadow1,
  BackgroundShadow2,
} from './featureSlider.style';
import Rocket from 'common/src/assets/image/saas/rocket_features.svg';
import Settings from 'common/src/assets/image/saas/settings_features.svg';
import DataIcon from 'common/src/assets/image/saas/data_features.svg';
import ReportIcon from 'common/src/assets/image/saas/report_features.svg';
import TargetIcon from 'common/src/assets/image/saas/target_features.svg';
import TeamIcon from 'common/src/assets/image/saas/team.svg';
import Logo from 'common/src/assets/image/saas/logo_small.svg';

const images = [
  {
    original: `${Logo}`,
    thumbnail: `${Rocket}`,
    thumbnailLabel: 'Structured ABM',
    originalAlt: 'slide one',
    thumbnailAlt: 'thumb one',
    path: 'beyondABM',
  },
  {
    original: `${Logo}`,
    thumbnail: `${DataIcon}`,
    thumbnailLabel: 'Data validation',
    originalAlt: 'slide four',
    thumbnailAlt: 'thumb four',
    path: 'data',
  },
  {
    original: `${Logo}`,
    thumbnail: `${Settings}`,
    thumbnailLabel: 'Automation at core',
    originalAlt: 'slide two',
    thumbnailAlt: 'thumb two',
    path: 'automation',
  },
  {
    original: `${Logo}`,
    thumbnail: `${TeamIcon}`,
    thumbnailLabel: 'Team management',
    originalAlt: 'slide three',
    thumbnailAlt: 'thumb three',
    path: 'usersAndTeam',
  },
  {
    original: `${Logo}`,
    thumbnail: `${TargetIcon}`,
    thumbnailLabel: 'Targets',
    originalAlt: 'slide five',
    thumbnailAlt: 'thumb five',
    path: 'targets',
  },
  {
    original: `${Logo}`,
    thumbnail: `${ReportIcon}`,
    thumbnailLabel: 'Report & Analytics',
    originalAlt: 'slide six',
    thumbnailAlt: 'thumb six',
    path: 'report',
  },
];

const FeatureSlider = ({ sectionSubTitle, sectionTitle, sectionHeader }) => {
  function handleClick(event, value) {
    var path = images[value].path;
    var element = document.getElementById(path);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  useEffect(() => {
    if (window) {
      var hash = window.location.hash;
      if (hash) {
        setTimeout(() => {
          hash = hash.slice(1, hash.length);
          var element = document.getElementById(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 1500);
      }
    }
  }, []);

  useEffect(() => {
    var index = 0;
    var elemnt = document.getElementsByClassName('image-gallery-thumbnail');
    if (elemnt.length) {
      setInterval(() => {
        if (elemnt && elemnt[index]) {
          elemnt[index].classList.remove('active');
          if (index < 5) {
            index++;
          } else {
            index = 0;
          }
          elemnt[index].classList.add('active');
        }
      }, 2000);
    }
  }, []);

  return (
    <FeatureSliderWrapper>
      <BackgroundShadow1 />
      <BackgroundShadow2 />
      <div className="FeatureSliderInnerX">
        <span> </span>
        <span> </span>
        <span> </span>
      </div>
      <Container>
        <Box className="FeatureSliderX">
          <ImageGallery
            onThumbnailClick={handleClick}
            original={Logo}
            items={images}
            play={true}
            className="Slider-img"
            showPlayButton={false}
            showFullscreenButton={false}
            showNav={false}
            disableThumbnailScroll={false}
            disableSwipe={true}
            //showBullets={true}
          />
        </Box>
      </Container>
    </FeatureSliderWrapper>
  );
};

// FeatureSlider style props
FeatureSlider.propTypes = {
  sectionSubTitle: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionHeader: PropTypes.object,
};

// FeatureSlider default style
FeatureSlider.defaultProps = {
  sectionHeader: {},
  sectionSubTitle: {
    content: 'OUR FEATURES',
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.13em',
    fontWeight: '700',
    color: '#1a73e8',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    content: 'OUR FEATURES',
    textAlign: 'center',
    fontSize: ['20px', '24px', '24px', '24px', '30px'],
    fontWeight: '400',
    //color: '#2a313c',
    color: '#1a73e8',
    letterSpacing: '-0.025em',
    mb: '0',
  },
};

export default FeatureSlider;
