import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import FeatureSectionTwoWrapper from './featureSliderTwo.style';
import Rocket from 'common/src/assets/image/saas/rocket_features.svg';
import Settings from 'common/src/assets/image/saas/settings_features.svg';
import DataIcon from 'common/src/assets/image/saas/data_features.svg';
import ReportIcon from 'common/src/assets/image/saas/report_features.svg';
import TargetIcon from 'common/src/assets/image/saas/target_features.svg';
import TeamIcon from 'common/src/assets/image/saas/team.svg';

const features = [
  {
    id: 1,
    image: `${Rocket}`,
    label: 'Structured ABM',
    originalAlt: 'slide one',
    path: 'beyondABM',
  },
  {
    id: 2,
    image: `${Settings}`,
    label: 'Automation at core',
    originalAlt: 'slide two',

    path: 'automation',
  },
  {
    id: 3,
    image: `${TeamIcon}`,
    label: 'Users & teams',
    originalAlt: 'slide three',

    path: 'usersAndTeam',
  },
  {
    id: 4,
    image: `${DataIcon}`,
    label: 'Data validation',
    originalAlt: 'slide four',

    path: 'data',
  },
  {
    id: 5,
    image: `${TargetIcon}`,
    label: 'Targets',
    originalAlt: 'slide five',

    path: 'targets',
  },
  {
    id: 6,

    image: `${ReportIcon}`,
    label: 'Report and analytics',
    originalAlt: 'slide six',

    path: 'report',
  },
];

const FeatureSection = ({
  row,
  col,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  featureTitle,
  iconStyle,
  contentStyle,
  blockWrapperStyle,
}) => {
  function handleClick(index) {
    var path = features[index].path;
    var element = document.getElementById(path);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  useEffect(() => {
    if (window) {
      var hash = window.location.hash;
      if (hash) {
        hash = hash.slice(1, hash.length);
        var element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }, []);

  return (
    <FeatureSectionTwoWrapper id="keyfeatures">
      <Container>
        <Box {...sectionHeader}>
          <Text {...sectionSubTitle} />
          <Heading {...sectionTitle} />
        </Box>
        <Box className="row" {...row}>
          {features.map((feature, index) => (
            <Box
              className="col"
              {...col}
              key={index}
              onClick={() => handleClick(index)}
              style={{ cursor: 'pointer' }}
            >
              <Fade bottom delay={index * 120}>
                <FeatureBlock
                  icon={
                    <Image
                      style={{ height: '70px', widht: '70px' }}
                      src={feature.image}
                      alt="Demo Image"
                    />
                  }
                  wrapperStyle={blockWrapperStyle}
                  iconStyle={iconStyle}
                  contentStyle={contentStyle}
                  title={<Heading content={feature.label} {...featureTitle} />}
                />
              </Fade>
            </Box>
          ))}
        </Box>
      </Container>
    </FeatureSectionTwoWrapper>
  );
};

// FeatureSection style props
FeatureSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

// FeatureSection default style
FeatureSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['56px', '56px'],
  },
  // sub section default style
  sectionSubTitle: {
    content: 'FEATURES',
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.13em',
    fontWeight: '700',
    color: '#1a73e8',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    content: 'Features that drive efficiency in your Sales Team',
    textAlign: 'center',
    fontSize: ['20px', '24px', '24px', '24px', '30px'],
    fontWeight: '400',
    color: '#2a313c',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // feature col default style
  col: {
    width: [1 / 2, 1 / 2, 1 / 3, 1 / 3, 1 / 3],
  },
  // feature block wrapper default style
  blockWrapperStyle: {
    p: ['10px', '20px', '20px', '40px'],
  },
  // feature icon default style
  iconStyle: {
    width: '75px',
    height: '75px',
    m: '0 auto',
    borderRadius: '50%',
    bg: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '32px',
    color: '#29cf8a',
    overflow: 'hidden',
    mb: '15px',
  },
  // feature content default style
  contentStyle: {
    textAlign: 'center',
  },
  // feature title default style
  featureTitle: {
    fontSize: ['16px', '18px'],
    fontWeight: '400',
    color: '#2a313c',
    lineHeight: '1.5',
    mb: '20px',
    letterSpacing: '-0.020em',
  },
  // feature description default style
  featureDescription: {
    fontSize: ['14px', '15px'],
    lineHeight: '1.84',
    color: '#343d48cc',
  },
};

export default FeatureSection;
