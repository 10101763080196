import React, { Fragment, useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import { Icon } from 'react-icons-kit';
import { mediaRecordOutline } from 'react-icons-kit/typicons/mediaRecordOutline';
import { plus } from 'react-icons-kit/typicons/plus';
import { starOutline } from 'react-icons-kit/typicons/starOutline';
import Text from 'common/src/components/Text';
import Image from 'common/src/components/Image';
import Heading from 'common/src/components/Heading';
import Tab, { Panel } from 'common/src/components/Tabs';
import Container from 'common/src/components/UI/Container';
import FeatureBlock from 'common/src/components/FeatureBlock';
import SectionWrapper, { TabArea, SectionHeader } from './features.style';

function SmoothVerticalScrolling(e, time, where) {
  var eTop = e.getBoundingClientRect().top;
  var eAmt = eTop / 100;
  var curTime = 0;
  while (curTime <= time) {
    window.setTimeout(SVS_B, curTime, eAmt, where);
    curTime += time / 100;
  }
}

function SVS_B(eAmt, where) {
  if (where == 'center' || where == '') window.scrollBy(0, eAmt / 2);
  if (where == 'top') window.scrollBy(0, eAmt);
}

const FeatureTab = () => {
  const data = useStaticQuery(graphql`
    query {
      saasJson {
        featuresTab {
          slogan
          title
          tab {
            id
            element
            color
            icon {
              publicURL
            }
            title
            description
          }
        }
      }
    }
  `);
  const { slogan, title, tab } = data.saasJson.featuresTab;
  function handleClick(element) {
    var element = document.getElementById(element);
    if (element) {
      SmoothVerticalScrolling(element, 700, 'top');
    }
  }
  return (
    <SectionWrapper>
      <Container>
        <SectionHeader>
          <Fade up>
            <Heading content={slogan} />
            <Heading as="h1" content={title} />
          </Fade>
        </SectionHeader>
        <Fade up delay={100}>
          <TabArea>
            <Tab active={0}>
              {tab.map((item, i) => {
                return (
                  <Panel
                    title={
                      <FeatureBlock
                        onClick={() => handleClick(item.element)}
                        style={{ '--color': `${item.color}` }}
                        iconPosition="left"
                        icon={
                          <Fragment>
                            <Icon className="plus" icon={plus} />
                            <Icon
                              className="circle"
                              icon={mediaRecordOutline}
                            />
                            <Image
                              style={{ height: '70px' }}
                              src={item.icon.publicURL}
                              alt={item.title}
                            />
                            <Icon className="star" icon={starOutline} />
                          </Fragment>
                        }
                        title={<Heading as="h3" content={item.title} />}
                        description={<Text content={item.description} />}
                      />
                    }
                    key={`app-tab--key${item.id}`}
                  ></Panel>
                );
              })}
            </Tab>
          </TabArea>
        </Fade>
      </Container>
    </SectionWrapper>
  );
};

export default FeatureTab;
