import styled from 'styled-components';

export const TopBootstrap = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`;

export const BottomBootstrap = styled.div`
  display: block;
  @media (max-width: 767px) {
    display: none !important;
  }
`;
